import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../components/layout'
import Hero from '../components/hero-image'

import FeatureWindow01 from './feature-window-01.png'
import FeatureWindow02 from './feature-window-02.png'
import FeatureWindow03 from './feature-window-03.png'
import FeatureDoor01 from './feature-door-01.png'
import FeatureDoor02 from './feature-door-02.png'
// import FeatureBespoke01 from './feature-bespoke-01.png'

const IndexPage = () => (
  <Layout>
    <Helmet title="We create handcrafted British made joinery | Rudd Joinery">
      <meta name="description" content="Handcrafted British made joinery that combines the beauty of the past with the technology of the present" />
    </Helmet>
    <Hero heroImage="home-hero" heroId="home-hero" nextId="our-goals">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 68.29 68.29"
        className="rudd-joinery"
      >
        <path d="M34.14,2.53A31.62,31.62,0,1,0,65.76,34.14,31.61,31.61,0,0,0,34.14,2.53Zm-4,41c0,8-3.37,12.76-8.76,12.76l-.58-1.16c2.6-.38,5.11-4.19,5.11-9.1V20.8c0-2.06-.41-2.54-4-3.23v-1.2h8.18Zm20.13,4.91h-7l-.19-.14a34.11,34.11,0,0,0-3-5.3l-6-8.81h-2V32.35H34.2c2.89,0,6.21-2.31,6.21-7,0-3.66-2.07-7.12-8-7.12h-.28V16.37h1c8.67,0,11.94,3.56,11.94,7.75,0,4.53-4,7.61-6.36,8.81v.63l6.94,10.15c2.07,3,3,3.08,4.28,3.18l.53,0Z" />
        <path d="M34.14,0A34.15,34.15,0,1,0,68.29,34.14,34.15,34.15,0,0,0,34.14,0Zm0,65.76A31.62,31.62,0,1,1,65.76,34.14,31.61,31.61,0,0,1,34.14,65.76Z" />
        <path d="M45.66,43.71,38.72,33.56v-.63c2.31-1.2,6.36-4.28,6.36-8.81,0-4.19-3.27-7.75-11.94-7.75h-1v1.88h.28c5.92,0,8,3.46,8,7.12,0,4.67-3.32,7-6.21,7H32.14v1.88h2l6,8.81a34.11,34.11,0,0,1,3,5.3l.19.14h7l.19-1.54-.53,0C48.69,46.79,47.73,46.7,45.66,43.71Z" />
        <path d="M22,17.57c3.54.69,3.92,1.17,4,3.23V46.07c0,4.91-2.51,8.72-5.11,9.1l.58,1.16c5.39,0,8.76-4.72,8.76-12.76V16.37H22Z" />
      </svg>
      <h1>
        We create handcrafted
        <br />
        British made joinery
      </h1>
      <h2>
        That combines the beauty of the past with the technology of the present.
      </h2>
    </Hero>
    <div className="section" id="our-goals">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading ico goals">Our goal</h2>
          <p>
            From windows and doors to staircases and garden rooms, we design,
            manufacture and install bespoke joinery for homeowners, architects
            and the construction trade across East Anglia. What's more, we offer
            these beautiful bespoke pieces at affordable prices.
          </p>
        </div>
      </div>
    </div>
    <div className="hero-bar">
      <div
        style={{ backgroundImage: 'url(/static/images/home-hero-bar-01.jpg)' }}
      />
    </div>
    <div className="section">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Our mission</h2>
          <p>
            Each and every one of our products is made to our exacting standards
            by our talented team of experienced craftsmen, in our facility. We
            only use sustainable sourced timbers including{' '}
            <a href="https://www.accoya.com/" rel="external">
              Accoya&reg;
            </a>{' '}
            timber a durable and sustainable timber that can withstand the test
            of time and all our products are available in a wide range of styles
            and finishes to suit most budgets.
          </p>
        </div>
      </div>
    </div>
    <div className="hero-bar">
      <div
        style={{ backgroundImage: 'url(/static/images/home-hero-bar-02.jpg)' }}
      />
      <div
        style={{ backgroundImage: 'url(/static/images/home-hero-bar-03.jpg)' }}
      />
    </div>
    <div className="section greenblue">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading ico range">Our range</h2>
          <p>
            After extensive research and development we have created a range of
            products that blend the beauty of the past with the latest
            performance technology. All of our products are of a superior
            quality, manufactured to your specific requirements so you are not
            limited to a standard range of sizes and styles.
          </p>
        </div>
      </div>
    </div>
    <div className="our-range greenblue">
      <div className="__block">
        <div
          className="story"
          data-sal-duration="600"
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2>Windows</h2>
          <p>
            Our range of{' '}
            <strong>high performance bespoke timber windows</strong> are
            manufactured using the very best quality engineered timber and
            hardware to provide an excellent combination of thermal performance
            and secure design.
          </p>
          <p>
            <Link to="/windows" className="cta">
              Find out more
            </Link>
          </p>
        </div>
      </div>
      <div className="__hero">
        <img
          src={FeatureWindow01}
          alt=""
          data-sal-duration="1200"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
        <img
          src={FeatureWindow02}
          alt=""
          data-sal-duration="1200"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
        <img
          src={FeatureWindow03}
          alt=""
          data-sal-duration="1200"
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
      </div>
    </div>
    <div className="our-range ovalroomblue">
      <div className="__block">
        <div
          className="story"
          data-sal-duration="600"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2>Doors</h2>
          <p>
            Our range of <strong>high performance bespoke timber doors</strong>{' '}
            are manufactured using the very best engineered timber and hardware
            to provide an excellent balance of stylish joinery and modern secure
            design.
          </p>
          <p>
            <Link to="/doors" className="cta">
              Find out more
            </Link>
          </p>
        </div>
      </div>
      <div className="__hero">
        <img
          src={FeatureDoor01}
          alt=""
          data-sal-duration="1200"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
        <img
          src={FeatureDoor02}
          alt=""
          data-sal-duration="1200"
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
      </div>
    </div>
    {/* <div className="our-range mousesback">
      <div className="__block">
        <div
          className="story"
          data-sal-duration="600"
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2>Bespoke</h2>
          <p>
            As well as our range of bespoke performance windows and doors we
            also provide a bespoke specialist service allowing us to design and manufacture
            products to meet your exact requirements including{' '}
            <strong>Staircases</strong> and <strong>Gates</strong>.
          </p>
          <p>
          <Link to="/bespoke" className="cta">Find out more</Link>
          </p>
        </div>
      </div>
      <div className="__hero">
        <img
          src={FeatureBespoke01}
          alt=""
          data-sal-duration="1200"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
      </div>
    </div> */}
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading ico eco">Eco-friendly</h2>
          <p>
            We only use wood sourced from managed sustainable forests and from
            the UK wherever possible. Many of our products are made from <a href="https://www.accoya.com">Accoya&reg;</a>
            timber, a naturally renewable and award-winning brand that reduces
            environmental impact and sourced from FSC&reg; woods.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
